<template>
  <div>
    <EarningHeadr @showPop="showPop" ref="EarningHeadr"></EarningHeadr>
    <div class="earningsCont">
      <div class="seleteBox">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="收益记录" name="1" lazy></el-tab-pane>
          <el-tab-pane label="提现记录" name="2" lazy></el-tab-pane>
          <el-tab-pane label="我的银行卡" name="3" lazy> </el-tab-pane>
        </el-tabs>
        <div class="df_Yc">
          <el-select
            v-show="activeName === '1'"
            v-model="getParams.type"
            placeholder="请选择"
            class="mr20"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-date-picker
            v-show="activeName !== '3'"
            v-model="dateTime"
            type="daterange"
            align="right"
            unlink-panels
            :clearable="false"
            range-separator="————"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            class="mr20"
          >
          </el-date-picker>
          <div
            class="searchButton"
            v-show="activeName !== '3'"
            @click="searchFun"
          >
            查询
          </div>
        </div>
      </div>
      <EarningRecord
        @setPageFun="setPageFun"
        :earningObj="earningObj"
        v-show="activeName === '1'"
      ></EarningRecord>
      <EarningDeposit
        @setPageFun="setPageFun"
        :depositObj="depositObj"
        v-show="activeName === '2'"
      ></EarningDeposit>
      <EarningBank
        :bankList="bankList"
        v-show="activeName === '3'"
      ></EarningBank>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="提现"
      :visible.sync="dialogFormVisible"
      width="440px"
      height="231px"
      class="dialogBox"
    >
      <el-form class="txBox" :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="银行卡" prop="bankId">
          <el-radio-group v-model="ruleForm.bankId">
            <el-radio
              :label="item.id"
              v-for="item in bankPopList"
              :key="item.id"
            >
              <div class="radioBox">
                <img
                  :src="
                    require('@/assets/image/bankImg/' + item.type + '_icon.png')
                  "
                  alt=""
                  v-if="item.type"
                />
                <img src="" alt="" v-else />
                <div class="radioBoxContent">
                  <p>{{ item.bankName }}</p>
                  <p>尾号:{{item.cardCode.substr(-4)}}</p>
                </div>
              </div>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="提现金额" style="display: flex" prop="money">
          <el-input v-model="ruleForm.money"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer popBottom">
        <div class="popBtn1" @click="dialogFormVisible = false">
          <p>取消</p>
        </div>
        <!-- <div class="popBtn2">
          <p>确定</p>
        </div> -->
        <el-button 
          class="popBtn2" 
          type="primary" 
          @click="submitForm('ruleForm')" 
          :loading="dialogLoading">确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import EarningRecord from "./componts/EarningRecord.vue";
import EarningDeposit from "./componts/EarningDeposit.vue";
import EarningBank from "./componts/EarningBank.vue";
import EarningHeadr from "./componts/EarningHeadr.vue";
import { getEarningRecord, getDepositRecode, getBankList,addWithdraw } from "@/Api";
import { getToken } from "@/utils/auth";

export default {
  name: "MyEarnings",
  data() {
    var validatePass = (rule, value, callback) => {
      if (Number.isInteger(Number(value)) && Number(value) >= 100) {
        callback();
      } else {
        callback(new Error("请输入大于等于100的整数"));
      }
    };
    return {
      rules: {
        bankId: [{ required: true, message: "请选择银行卡", trigger: "blur" }],
        money: [
          { required: true, message: "请输入提现金额", trigger: "blur" },
          { type: "number", validator: validatePass, trigger: "change" },
        ],
      },
      ruleForm: {
        bankId: "",
        money: "",
      },
      activeName: "1",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dateTime: "",
      options: [
        {
          value: "1",
          label: "课程",
        },
        {
          value: "2",
          label: "资料库",
        },
        {
          value: "3",
          label: "SU模型",
        },
        {
          value: "4",
          label: "PS素材",
        },
        {
          value: "5",
          label: "3D模型",
        },
        {
          value: "6",
          label: "方案文本",
        },
        {
          value: "7",
          label: "贴图",
        },
      ],
      //请求参数
      getParams: {
        token: getToken(),
        startTime: "",
        endTime: "",
        type: "",
        page: 1,
      },
      //  收益记录
      earningObj: {
        data: [],
        total: 0,
        per_page: 10,
      },
      //  提现记录
      depositObj: {
        data: [],
        total: 0,
        per_page: 10,
      },
      //  银行卡列表
      bankList: [],
      dialogFormVisible: true,
      dialogLoading: false,
      bankPopList: [],
    };
  },
  components: {
    EarningRecord,
    EarningDeposit,
    EarningBank,
    EarningHeadr,
  },
  created() {
    this.activeName = this.$route.query.activeName
      ? this.$route.query.activeName
      : "1";
    this.handleClick();
  },
  methods: {
    submitForm(formName) {
      this.dialogLoading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addWithdraw({token:getToken(),money:this.ruleForm.money,bankId:this.ruleForm.bankId}).then((res) => {
            if(res.data.message == 'addSuccess'){
               this.$message.success('申请提现成功,请耐心等待')
               this.dialogFormVisible = false;
               this.dialogLoading = false;
               this.$refs.EarningHeadr.getMyIncomeStatistic()
               this.handleClick()
            } else if(res.data.message == 'balanceNotEnough'){
              this.$message.warning('余额不足！')
              this.dialogLoading = false;
            } else {
              this.dialogLoading = false;
            }
          })
        } else {
          this.dialogLoading = false;
        }
      });
    },
    
    showPop() {
      getBankList(getToken()).then((res) => {
        this.bankPopList = res.data.data;
        this.dialogFormVisible = true;
      });
    },
    //点击查询
    searchFun() {
      this.getParams.page = 1;
      if (this.activeName === "1") {
        this.getEarningRecord();
      } else if (this.activeName === "2") {
        this.getDepositRecode();
      }
    },
    //分页
    setPageFun(page) {
      this.getParams.page = page;
      if (this.activeName === "1") {
        this.getEarningRecord();
      } else if (this.activeName === "2") {
        this.getDepositRecode();
      }
    },
    //获取收益记录
    getEarningRecord() {
      getEarningRecord(this.getParams).then((res) => {
        this.earningObj = res.data;
      });
    },
    //获取提现记录
    getDepositRecode() {
      getDepositRecode(this.getParams).then((res) => {
        this.depositObj = res.data;
      });
    },
    //获取银行卡列表
    getBankList() {
      getBankList(getToken()).then((res) => {
        this.bankList = res.data.data;
      });
    },
    handleClick() {
      this.getParams = this.$options.data().getParams;
      this.dateTime = [];
      if (this.activeName === "1") {
        this.getEarningRecord();
      } else if (this.activeName === "2") {
        this.getDepositRecode();
      } else if (this.activeName === "3") {
        this.getBankList();
      }
    },
  },

  watch: {
    dateTime(val) {
      this.getParams.startTime = val[0];
      this.getParams.endTime = val[1];
    },
  },
};
</script>
<style scoped>
.searchButton {
  width: 68px;
  height: 30px;
  background: #f46600;
  border-radius: 4px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  font-size: 0.14rem;
  cursor: pointer;
}
.seleteBox >>> .el-select .el-input .el-select__caret {
  font-size: 0.14rem;
}
.seleteBox >>> .el-input__inner {
  width: 120px;
  height: 30px;
  line-height: 30px;
}
.seleteBox >>> .el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
  font-size: 00.14rem;
}
.seleteBox >>> .el-date-editor .el-range-separator {
  line-height: 25px;
  width: 10%;
}
.seleteBox >>> .el-date-editor .el-range__icon {
  line-height: 25px;
}
.seleteBox >>> .el-range-editor.el-input__inner {
  height: 30px;
  width: 250px;
}
.seleteBox >>> .el-tabs__header {
  margin: 0;
}
.seleteBox >>> .el-tabs__item {
  height: 00.4rem;
  line-height: 00.4rem;
}
.seleteBox >>> .el-tabs__active-bar {
  background-color: #f46600;
  bottom: 1px;
}
.seleteBox >>> .el-tabs__item.is-active {
  color: #f46600;
}
.seleteBox >>> .el-tabs__item:hover {
  color: #f46600;
  cursor: pointer;
}
.seleteBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 0;
  position: relative;
  line-height: 0.6rem;
  height: 0.6rem;
  margin-top: 0.2rem;
}
.seleteBox::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #e4e7ed;
  z-index: 1;
}
.earningsCont {
  background: #ffffffff;
  border-radius: 00.04rem;
  padding: 0 0.2rem 0.3rem;
  min-height: 6.46rem;
}
.popBottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #f9f9f9;
  opacity: 1;
  border-radius: 4px;
}
.popBtn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  background: #eeeeee;
  opacity: 1;
  border-radius: 4px;
  margin-right: 20px;
}

.popBtn1 p {
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  opacity: 1;
}

.popBtn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  background: #f46600;
  opacity: 1;
  border-radius: 4px;
  border: 0;
}

.popBtn2 p {
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
}
.dialogBox >>> .el-dialog__title {
  width: 80px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #040404;
  opacity: 1;
}
.dialogBox >>> .el-dialog__header {
  border: 1px solid #eeeeee;
}
.dialogBox >>> .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 240px;
}
.dialogBox >>> .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  padding-left: 55px;
}
.dialogBox >>> .el-dialog__footer {
  padding: 0 20px;
  text-align: right;
  box-sizing: border-box;
  background: #f9f9f9;
}
.txBox >>> .el-radio {
  color: #606266;
  font-weight: 500;
  cursor: pointer;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.txBox >>> .el-radio-group {
  font-size: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.radioBox {
  width: 125px;
  height: 40px;
  display: flex;
  padding: 0.05rem;
  border: 1px solid #aaa;
  border-radius: 0.04rem;
  align-items: center;
}
.radioBox img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 5px;
}
.radioBoxContent p:first-child {
  font-family: PingFang SC;
  font-size: 12px;
}
.radioBoxContent p:last-child {
  font-family: PingFang SC;
  font-size: 14px;
}
.txBox >>> .el-radio__input.is-checked + .el-radio__label {
  color: #fd7624 !important;
}
.txBox >>> .el-radio__input.is-checked .el-radio__inner {
  background: #fd7624 !important;
  border-color: #fd7624 !important;
}
</style>